import logo from './whoraly.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="" alt="logo" />
        <p>
          pix.whoraly.com in under Whoraly support.
        </p>
        <p style={{margin:'0px'}}>
        Whoraly is
        </p>
        <p style={{margin:'0px'}}>
        Who really are you?
        </p>
        <div style={{margin:'20px 10px 40px'}}>
          <p style={{margin:'0px'}}>
            A Marketing platform
          </p>
          <p style={{margin:'0px'}}>
            with Psychology section.
          </p>
        </div>
        <a
          className="App-link"
          href="https://whoraly.com"
          rel="noopener noreferrer"
        >
        Marketing and Advertising
        </a>
        <a
          className="App-link"
          href="https://psychology.whoraly.com"
          rel="noopener noreferrer"
        >
        Psychology
        </a>
      </header>
    </div>
  );
}

export default App;
